<template>
    <div class="text-center">
        <router-link class="btn btn-dark mr-1 mb-3" 
            :to="{name: 'cart.orders'}">
        My Orders
        </router-link>
        <router-link class="btn btn-dark mr-1 mb-3" 
            :to="{name: 'updateInfo'}">
        Edit Profile
        </router-link>
        <router-link class="btn btn-dark mr-1 mb-3" 
            :to="{name: 'changePassword'}">
        Change Password
        </router-link>
    </div>
</template>

<script>
export default {
    name: 'basic-info',
    methods: {

    },//methods end here
    mounted(){
        
    }
}
</script>

<style scoped>
    a{
        margin-bottom: 0;
    
    }

</style>